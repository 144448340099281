import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { withCurrentUser } from "./modules/auth/containers";
import ErrorPageRoutes from "./modules/errorPage/routes";
import MainLayout from "./modules/layout/containers/MainLayout";
import AuthRoutes from "./modules/auth/routes";
import UserRoutes from "./modules/user/routes";
import UserManagementRoutes from "./modules/usermanagement/routes";
import UserRequestRoutes from "./modules/userRequest/routes";
import BlogRoutes from "./modules/blog/routes";
import ContentCategoryRoutes from "./modules/contentCategory/routes";
import CountryCategoryRoutes from "./modules/countryCategory/routes";
import PageRoutes from "./modules/page/routes";
import TravelRoutes from "./modules/travel/routes";
import OrderRoutes from "./modules/order/routes";
import UserConfirmRoutes from "./modules/userConfirm/routes";
import ArticleRoutes from "./modules/article/routes";
import PostRoutes from "./modules/posts/routes";
import ArticleTypeRoutes from "./modules/articleType/routes";
import SkillRoutes from "./modules/skill/routes";
import TravelScheduleRoutes from "./modules/travelSchedule/routes";
import ChatRoutes from "./modules/chats/routes";
const ROLES = {
  MEMBER: "member",
  ADMIN: "admin",
  GUIDE: "guide",
  COMPANY: "company",
  MENTOR: "mentor",
};

const renderRoutes = (currentUser) => {
  if (currentUser) {
    if (currentUser.role !== ROLES.MEMBER) {
      return (
        <MainLayout>
          <Switch>
            {UserRoutes()}
            {UserManagementRoutes(currentUser, ROLES)}
            {UserRequestRoutes(currentUser, ROLES)}
            {BlogRoutes()}
            {ErrorPageRoutes()}
            {ContentCategoryRoutes(currentUser, ROLES)}
            {CountryCategoryRoutes(currentUser, ROLES)}
            {PageRoutes(currentUser, ROLES)}
            {TravelRoutes(currentUser, ROLES)}
            {OrderRoutes(currentUser, ROLES)}
            {UserConfirmRoutes(currentUser, ROLES)}
            {ArticleRoutes()}
            {PostRoutes(currentUser, ROLES)}
            {ArticleTypeRoutes(currentUser, ROLES)}
            {SkillRoutes(currentUser, ROLES)}
            {TravelScheduleRoutes(currentUser, ROLES)}
            {ChatRoutes(currentUser, ROLES)}
            {ROLES.ADMIN === currentUser.role ? (
              <Redirect to="/user/management/list" />
            ) : (
              <Redirect to="/blogger/list" />
            )}
          </Switch>
        </MainLayout>
      );
    }
  }

  return (
    <Switch>
      <AuthRoutes />
    </Switch>
  );
};

const Routes = ({ currentUser }) => (
  <Router>{renderRoutes(currentUser)}</Router>
);

export default withCurrentUser(Routes);
