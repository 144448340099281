import { createContext, useEffect, useState } from "react";
import T from "i18n-react";

import translations from "../../locales";

const TranslationContext = createContext({
  currentLanguage: "mn",
});

export const Translation = (props) => {
  const [currentLanguage, setCurrentLanguage] = useState("mn");

  useEffect(() => {
    changeLanguge("mn");
  }, [currentLanguage]);

  const changeLanguge = (languageCode) => {
    T.setTexts(translations[languageCode]);
    setCurrentLanguage(languageCode);
  };

  const language = (msg) => {
    return T.translate(msg);
  };

  return (
    <TranslationContext.Provider
      value={{ currentLanguage, changeLanguge, language }}
    >
      {props.children};
    </TranslationContext.Provider>
  );
};

export default TranslationContext;
