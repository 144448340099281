import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
const SkillList = lazy(() => import("./containers/List"));

const SkillListComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <SkillList history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const routes = (currentUser, roles) => {
  return currentUser.role === roles.ADMIN
    ? [
        <Route
          path="/skill/list"
          key="/skill/list"
          component={SkillListComponent}
        />,
      ]
    : [];
};

export default routes;
