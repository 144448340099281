import { lazy } from "react";

import { Translation } from "../../contexts/LanguageContext";
const MainLayout = lazy(() => import("../components/MainLayout"));

const MainLayoutContainer = (props) => {
  const updatedProps = {
    ...props,
  };
  return (
    <Translation>
      <MainLayout {...updatedProps} />;
    </Translation>
  );
};

export default MainLayoutContainer;
