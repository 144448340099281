import gql from "graphql-tag";

const currentUser = gql`
  query currentUser {
    currentUser {
      _id
      lastName
      firstName
      email
      phonePrefix
      phoneNumber
      role
      avatar
      createdDate
    }
  }
`;

const users = gql`
  query users(
    $searchValue: String
    $isActive: Boolean
    $phoneNumber: String
    $role: String
  ) {
    users(
      searchValue: $searchValue
      isActive: $isActive
      phoneNumber: $phoneNumber
      role: $role
    ) {
      _id
      isSpecial
      lastName
      firstName
      email
      phoneNumber
      phonePrefix
      countryCode
      avatar
      gender
      role
      isActive
      reviewTotalPercent
      orderTotalCount
      createdDate
    }
  }
`;

const userDetail = gql`
  query userDetail($_id: String!) {
    userDetail(_id: $_id) {
      _id
      lastName
      firstName
      email
      phoneNumber
      phonePrefix
      countryCode
      avatar
      gender
      role
      isActive
      createdDate
    }
  }
`;

const userRequests = gql`
  query userRequests($userId: String, $status: String, $role: String) {
    userRequests(userId: $userId, status: $status, role: $role) {
      _id
      bio
      status
      meetPrice
      onlinePrice
      role
      reason
      userId
      countryCategoryIds
      createdUser {
        firstName
      }
      createdDate
      modifiedUser {
        firstName
      }
      modifiedDate
    }
  }
`;

const userRequestDetail = gql`
  query userRequestDetail($_id: String!) {
    userRequestDetail(_id: $_id) {
      _id
      bio
      status
      meetPrice
      onlinePrice
      role
      reason
      userId
      countryCategoryIds
      createdUser {
        firstName
        lastName
        phoneNumber
        email
        avatar
        phonePrefix
        countryCode
        gender
        role
        isActive
        createdDate
        term
      }
      livingCountry {
        _id
        title
      }
      skills {
        _id
        name
      }
      traveledCategory {
        _id
        title
      }
      contentCategory {
        _id
        title
      }
      countryCategory {
        title
        _id
      }
      createdDate
      modifiedUser {
        firstName
      }
      modifiedDate
    }
  }
`;

const countryCategories = gql`
  query countryCategories($isHideChildren: Boolean, $isGuide: Boolean) {
    countryCategories(isHideChildren: $isHideChildren, isGuide: $isGuide) {
      _id
      title
      description
      picture
      parentId
      isActive
      children {
        title
        _id
        description
        picture
        parentId
        isActive
      }
    }
  }
`;

const countryCategoryDetail = gql`
  query countryCategoryDetail($_id: String!) {
    countryCategoryDetail(_id: $_id) {
      _id
      title
      description
      picture
      parentId
      isActive
      children {
        title
        _id
        description
        picture
        parentId
        isActive
      }
    }
  }
`;

// content blog

const blogs = gql`
  query blogs(
    $categoryId: String
    $searchValue: String
    $contentCategoryIds: [String]
    $page: Int
    $perPage: Int
  ) {
    blogs(
      categoryId: $categoryId
      contentCategoryIds: $contentCategoryIds
      page: $page
      perPage: $perPage
      searchValue: $searchValue
    ) {
      _id
      title
      content
      contentType
      picture
      categoryId
      contentCategoryIds
      createdDate
      rateTotalPercent
      isSpecial
      createdUser {
        _id
        firstName
      }
      contentCategories {
        _id
        title
      }
      countryCategory {
        parentId
        title
        _id
      }
    }
  }
`;

const myBlogs = gql`
  query myBlogs(
    $categoryId: String
    $searchValue: String
    $contentCategoryIds: [String]
    $page: Int
    $perPage: Int
  ) {
    myBlogs(
      categoryId: $categoryId
      contentCategoryIds: $contentCategoryIds
      page: $page
      perPage: $perPage
      searchValue: $searchValue
    ) {
      _id
      title
      content
      isSpecial
      contentType
      picture
      categoryId
      contentCategoryIds
      createdDate
      rateTotalPercent
      isSpecial
      createdUser {
        _id
        firstName
      }
      contentCategories {
        _id
        title
      }
      countryCategory {
        parentId
        title
        _id
      }
    }
  }
`;

const blogDetail = gql`
  query blogDetail($_id: String!) {
    blogDetail(_id: $_id) {
      _id
      title
      content
      picture
      contentType
      categoryId
      contentCategoryIds
      rateTotalPercent
      isSpecial
      countryCategory {
        parentId
        title
        _id
      }
    }
  }
`;

const contentCategories = gql`
  query contentCategories($type: CONTENT_TYPE) {
    contentCategories(type: $type) {
      _id
      title
      description
      blogCount
      type
    }
  }
`;

const pages = gql`
  query pages($searchValue: String) {
    pages(searchValue: $searchValue) {
      _id
      subject
      content
      code
      pictures
      createdUser {
        firstName
      }
    }
  }
`;

const pageDetail = gql`
  query pageDetail($_id: String) {
    pageDetail(_id: $_id) {
      _id
      subject
      content
      code
      pictures
      createdUser {
        firstName
      }
    }
  }
`;

const logs = gql`
  query logs(
    $userId: String
    $page: Int
    $perPage: Int
    $actionType: ACTIONTYPE
    $collectionDbType: COLLECTIONTYPE
    $actionId: String
  ) {
    logs(
      userId: $userId
      page: $page
      perPage: $perPage
      actionId: $actionId
      actionType: $actionType
      collectionDbType: $collectionDbType
    ) {
      _id
      actionType
      actionId
      collectionDbType
      description
      createdDate
      createdUser {
        _id
        lastName
        firstName
      }
    }
  }
`;

const travels = gql`
  query travels(
    $page: Int
    $perPage: Int
    $searchValue: String
    $userId: String
    $categoryId: String
    $contentCategoryIds: [String]
    $isCompany: Boolean
  ) {
    travels(
      page: $page
      perPage: $perPage
      userId: $userId
      searchValue: $searchValue
      categoryId: $categoryId
      contentCategoryIds: $contentCategoryIds
      isCompany: $isCompany
    ) {
      _id
      title
      about
      price
      packages
      isCompany
      isSpecial
      pictures
      isActive
      categoryId
      dayCount
      contentCategoryIds

      days {
        dayName
        time
        description
        title
      }

      createdDate
      createdUser {
        firstName
      }
      modifiedUser {
        firstName
      }
      contentCategories {
        title
        _id
      }
      category {
        title
      }
    }
  }
`;

const myTravels = gql`
  query myTravels(
    $page: Int
    $perPage: Int
    $searchValue: String
    $categoryId: String
    $contentCategoryIds: [String]
    $isCompany: Boolean
  ) {
    myTravels(
      page: $page
      perPage: $perPage
      searchValue: $searchValue
      categoryId: $categoryId
      contentCategoryIds: $contentCategoryIds
      isCompany: $isCompany
    ) {
      _id
      title
      about
      price
      dayCount
      packages
      isCompany
      pictures
      isActive
      categoryId
      contentCategoryIds

      days {
        dayName
        time
        description
        title
      }

      createdDate
      createdUser {
        firstName
      }
      modifiedUser {
        firstName
      }
      contentCategories {
        title
        _id
      }
      category {
        title
      }
    }
  }
`;

const travelDetail = gql`
  query travelDetail($_id: String!) {
    travelDetail(_id: $_id) {
      _id
      title
      about
      price
      packages
      isCompany
      isActive
      dayCount
      categoryId
      contentCategoryIds
      pictures
      days {
        title
        time
        dayName
        description
      }

      createdDate
      createdUser {
        firstName
      }
      modifiedUser {
        firstName
      }
      contentCategories {
        title
        _id
      }
      category {
        _id
        parentId
        title
      }
    }
  }
`;

const orders = gql`
  query orders(
    $status: String
    $orderNumber: String
    $userName: String
    $page: Int
    $perPage: Int
  ) {
    orders(
      status: $status
      orderNumber: $orderNumber
      userName: $userName
      page: $page
      perPage: $perPage
    ) {
      _id
      status
      travelId
      userId
      guideId
      mentorId
      orderType
      price
      priceCount
      scheduleIds
      appFeePrice
      insurancePrice
      totalPrice
      orderNumber
      travel {
        title
      }
      paymentType
      createdDate
      contractorUser {
        firstName
        lastName
        phoneNumber
        phonePrefix
        email
        role
        creator {
          _id
          bio
          meetPrice
          onlinePrice
          countryCategory {
            _id
            title
          }
        }
      }
      createdUser {
        firstName
      }
      modifiedDate
      modifiedUser {
        firstName
      }
    }
  }
`;

const orderDetail = gql`
  query orderDetail($_id: String!) {
    orderDetail(_id: $_id) {
      _id
      status
      travelId
      userId
      guideId
      mentorId
      orderType
      price
      priceCount
      scheduleIds
      appFeePrice
      insurancePrice
      totalPrice
      orderNumber
      travel {
        title
        price
      }
      paymentType
      createdDate
      travelStatus {
        reason
        status
        _id
        constractorUserStatus
        orderUserStatus
      }
      travelSchedule {
        dates
      }
      usedScheduleDays {
        _id
        freeDate
      }
      contractorUser {
        firstName
        lastName
        phoneNumber
        phonePrefix
        email
        role
        creator {
          _id
          bio
          meetPrice
          onlinePrice
          countryCategory {
            _id
            title
          }
        }
      }

      createdUser {
        firstName
        lastName
        phoneNumber
        phonePrefix
        email
      }
      modifiedDate
      modifiedUser {
        firstName
      }
    }
  }
`;

const allPhoneCountries = gql`
  query allPhoneCountries {
    allPhoneCountries
  }
`;

const myNotifications = gql`
  query myNotifications {
    myNotifications {
      _id
      title
      body
      createdDate
    }
  }
`;
const myBankAccount = gql`
  query myBankAccount {
    myBankAccount {
      _id
      bankCode
      bankName
      bankAccountName
      bankAccount
      createdUser {
        firstName
      }
    }
  }
`;
const allBanks = gql`
  query allBanks {
    allBanks
  }
`;

const userConfirms = gql`
  query userConfirms($status: String, $page: Int, $perPage: Int) {
    userConfirms(status: $status, page: $page, perPage: $perPage) {
      _id
      status
      reason
      front_card_picture
      back_card_picture
      signature_picture
      selfie_picture
      createdDate
      modifiedDate
      createdUser {
        _id
        isSpecial
        lastName
        firstName
        email
        phoneNumber
        phonePrefix
        countryCode
        avatar
        gender
        role
        isActive
        createdDate
      }
      modifiedUser {
        firstName
      }
    }
  }
`;

const userConfirmDetail = gql`
  query userConfirmDetail($_id: String!) {
    userConfirmDetail(_id: $_id) {
      _id
      status
      reason
      front_card_picture
      back_card_picture
      signature_picture
      selfie_picture
      createdDate
      modifiedDate
      createdUser {
        _id
        isSpecial
        lastName
        firstName
        email
        phoneNumber
        phonePrefix
        countryCode
        avatar
        gender
        role
        isActive
        createdDate
      }
      modifiedUser {
        firstName
      }
    }
  }
`;

const myArticles = gql`
  query myArticles {
    myArticles {
      _id
      title
      description
      pictures
      articleTypeIds
      countryCategoryId
      countryCategory {
        _id
        title
      }
      articleTypes {
        _id
        name
      }
      tourItemIncluded
      tourItemNotIncluded
      information
      day
      tourPrice
      type
      createdDate
      createdUserId
      createdUser {
        _id
        firstName
      }
    }
  }
`;

const adminArticles = gql`
  query adminArticles {
    adminArticles {
      _id
      title
      description
      pictures
      articleTypeIds
      countryCategoryId
      countryCategory {
        _id
        title
      }
      articleTypes {
        _id
        name
      }
      tourItemIncluded
      tourItemNotIncluded
      information
      day
      tourPrice
      type
      createdDate
      createdUserId
      createdUser {
        _id
        firstName
      }
    }
  }
`;

const articleDetail = gql`
  query articleDetail($_id: String!) {
    articleDetail(_id: $_id) {
      _id
      title
      description
      pictures
      articleTypeIds
      countryCategoryId
      countryCategory {
        _id
        title
        parentId
        children {
          _id
          title
        }
      }
      articleTypes {
        _id
        name
      }
      tourItemIncluded
      tourItemNotIncluded
      information
      day
      tourPrice
      type
      createdDate
      createdUserId
      createdUser {
        _id
        firstName
      }
    }
  }
`;

const articleTypes = gql`
  query articleTypes($name: String) {
    articleTypes(name: $name) {
      _id
      name
      createdDate
      createdUser {
        firstName
        lastName
      }
    }
  }
`;

const articleTypeCount = gql`
  query articleTypeCount($name: String) {
    articleTypeCount(name: $name)
  }
`;

const myPosts = gql`
  query myPosts($page: Int, $perPage: Int) {
    myPosts(page: $page, perPage: $perPage) {
      _id
      description
      picture
      likes
      comments {
        _id
        postId
        comment
        createdUser {
          _id
          firstName
        }
      }
      createdUser {
        _id
        firstName
      }
    }
  }
`;
const posts = gql`
  query posts($page: Int, $perPage: Int, $searchValue: String) {
    posts(page: $page, perPage: $perPage, searchValue: $searchValue) {
      _id
      description
      picture
      likes
      comments {
        _id
        postId
        comment
        createdUser {
          _id
          firstName
        }
      }
      createdUser {
        _id
        firstName
      }
    }
  }
`;

const postDetail = gql`
  query postDetail($_id: String!) {
    postDetail(_id: $_id) {
      _id
      description
      picture
      likes
      comments {
        _id
        postId
        comment
        createdDate
        createdUser {
          _id
          firstName
        }
      }
      createdUser {
        _id
        firstName
      }
    }
  }
`;

const skills = gql`
  query skills($name: String) {
    skills(name: $name) {
      _id
      name
      createdDate
      createdUser {
        _id
        lastName
        firstName
      }
    }
  }
`;

const travelSchedules = gql`
  query travelSchedules {
    travelSchedules {
      _id
      startDate
      dates
      travel {
        _id
        title
        dayCount
      }
      isActive
    }
  }
`;
const chats = gql`
  query chats($orderId: String!) {
    chats(orderId: $orderId) {
      _id
      message
      picture
      link
      createdDate
      order {
        status
        orderNumber
      }
      createdUser {
        _id
        firstName
        avatar
      }
    }
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  currentUser,
  users,
  userDetail,
  userRequests,
  userRequestDetail,
  countryCategories,
  countryCategoryDetail,
  blogs,
  myBlogs,
  blogDetail,
  contentCategories,
  pages,
  pageDetail,
  logs,
  travels,
  myTravels,
  travelDetail,
  orders,
  orderDetail,
  allPhoneCountries,
  myNotifications,
  myBankAccount,
  allBanks,
  userConfirms,
  userConfirmDetail,
  myArticles,
  adminArticles,
  articleDetail,
  articleTypes,
  articleTypeCount,
  myPosts,
  posts,
  postDetail,
  skills,
  travelSchedules,
  chats,
};
