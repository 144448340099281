import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
const Form = lazy(() => import("./containers/Form"));
const List = lazy(() => import("./containers/List"));

const ListComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <List history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const FormComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <Form history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const routes = () => [
  <Route path="/blogger/list" key="/blogger/list" component={ListComponent} />,
  <Route path="/blog/form" key="/blog/form" component={FormComponent} />,
];

export default routes;
