import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
const Login = lazy(() => import("./containers/Login"));
const ForgotPassword = lazy(() => import("./containers/forgotPassword"));
const ResetPassword = lazy(() => import("./containers/resetPassword"));

const LoginComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <Login history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const ForgotPasswordComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <ForgotPassword history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const ResetPasswordComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <ResetPassword history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const routes = () => [
  <Route
    path="/"
    key="/"
    component={LoginComponent}
    exact={true}
    strict={true}
  />,
  <Route
    path="/forgot/password"
    key="/forgot/password"
    component={ForgotPasswordComponent}
    exact={true}
    strict={true}
  />,
  <Route
    path="/reset/password"
    key="/reset/password"
    component={ResetPasswordComponent}
    exact={true}
    strict={true}
  />,
];

export default routes;
