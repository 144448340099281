import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import { Spin } from "antd";
const Error404 = lazy(() => import("./404"));

const Error404Component = ({ history }) => {
  return (
    <Suspense fallback={<Spin />}>
      <Error404 history={history} />
    </Suspense>
  );
};

const routes = () => [
  <Route path="/404" key="/404" component={Error404Component} />,
];

export default routes;
