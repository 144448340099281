import gql from "graphql-tag";
const login = gql`
  mutation login($email: String!, $password: String!, $isMobile: Boolean!) {
    login(email: $email, password: $password, isMobile: $isMobile)
  }
`;

const forgotPassword = gql`
  mutation forgotPassword($email: String!) {
    forgotPassword(email: $email)
  }
`;

const resetPassword = gql`
  mutation resetPassword($token: String!, $newPassword: String!) {
    resetPassword(token: $token, newPassword: $newPassword)
  }
`;

const userCreate = gql`
  mutation userCreate(
    $firstName: String!
    $lastName: String!
    $email: String!
    $phonePrefix: String!
    $countryCode: String!
    $phoneNumber: Float!
    $gender: GENDER!
    $role: String!
    $password: String!
  ) {
    userCreate(
      firstName: $firstName
      lastName: $lastName
      email: $email
      phonePrefix: $phonePrefix
      countryCode: $countryCode
      phoneNumber: $phoneNumber
      gender: $gender
      role: $role
      password: $password
    )
  }
`;

const userUpdate = gql`
  mutation userUpdate(
    $_id: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phonePrefix: String!
    $countryCode: String!
    $phoneNumber: Float!
    $gender: GENDER!
    $role: String!
    $password: String
  ) {
    userUpdate(
      _id: $_id
      firstName: $firstName
      lastName: $lastName
      email: $email
      phonePrefix: $phonePrefix
      countryCode: $countryCode
      phoneNumber: $phoneNumber
      gender: $gender
      role: $role
      password: $password
    )
  }
`;

const userIsSpecial = gql`
  mutation userIsSpecial($userId: String!) {
    userIsSpecial(userId: $userId)
  }
`;
const changePassword = gql`
  mutation changePassword($oldPassword: String!, $newPassword: String!) {
    changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;

//blog

const blogAdd = gql`
  mutation blogAdd(
    $title: String!
    $content: String!
    $picture: String!
    $categoryId: String!
    $contentCategoryIds: [String]!
    $contentType: CONTENTTYPE!
  ) {
    blogAdd(
      title: $title
      content: $content
      picture: $picture
      categoryId: $categoryId
      contentCategoryIds: $contentCategoryIds
      contentType: $contentType
    )
  }
`;

const blogEdit = gql`
  mutation blogEdit(
    $_id: String!
    $title: String!
    $content: String!
    $picture: String!
    $categoryId: String!
    $contentCategoryIds: [String]!
    $contentType: CONTENTTYPE!
  ) {
    blogEdit(
      _id: $_id
      title: $title
      content: $content
      picture: $picture
      categoryId: $categoryId
      contentCategoryIds: $contentCategoryIds
      contentType: $contentType
    )
  }
`;

const blogRemove = gql`
  mutation blogRemove($_id: String!) {
    blogRemove(_id: $_id)
  }
`;
const blogSpecial = gql`
  mutation blogSpecial($_id: String!) {
    blogSpecial(_id: $_id)
  }
`;
const contentCategoryAdd = gql`
  mutation contentCategoryAdd(
    $title: String!
    $description: String!
    $type: CONTENT_TYPE!
  ) {
    contentCategoryAdd(title: $title, description: $description, type: $type)
  }
`;

const contentCategoryEdit = gql`
  mutation contentCategoryEdit(
    $_id: String!
    $title: String!
    $description: String!
    $type: CONTENT_TYPE!
  ) {
    contentCategoryEdit(
      _id: $_id
      title: $title
      description: $description
      type: $type
    )
  }
`;

//country

const countryCategoryAdd = gql`
  mutation countryCategoryAdd(
    $title: String!
    $description: String!
    $picture: String
    $parentId: String
    $isActive: Boolean
  ) {
    countryCategoryAdd(
      title: $title
      description: $description
      picture: $picture
      parentId: $parentId
      isActive: $isActive
    )
  }
`;

const countryCategoryEdit = gql`
  mutation countryCategoryEdit(
    $_id: String!
    $title: String!
    $description: String!
    $picture: String
    $parentId: String
    $isActive: Boolean
  ) {
    countryCategoryEdit(
      _id: $_id
      title: $title
      description: $description
      picture: $picture
      parentId: $parentId
      isActive: $isActive
    )
  }
`;

const pageCreate = gql`
  mutation pageCreate($subject: String!, $content: String, $code: String!) {
    pageCreate(subject: $subject, content: $content, code: $code)
  }
`;

const pageUpdate = gql`
  mutation pageUpdate(
    $_id: String!
    $subject: String!
    $content: String
    $code: String!
  ) {
    pageUpdate(_id: $_id, subject: $subject, content: $content, code: $code)
  }
`;

const pageRemove = gql`
  mutation pageRemove($_id: String!) {
    pageRemove(_id: $_id)
  }
`;

const travelAdd = gql`
  mutation travelAdd(
    $title: String!
    $about: String!
    $price: Int!
    $pictures: [String]!
    $dayCount: Int!
    $packages: [PACKAGES]
    $categoryId: String!
    $contentCategoryIds: [String]!
    $days: [TravelDayInput]!
  ) {
    travelAdd(
      title: $title
      about: $about
      dayCount: $dayCount
      pictures: $pictures
      price: $price
      packages: $packages
      categoryId: $categoryId
      contentCategoryIds: $contentCategoryIds
      days: $days
    )
  }
`;

const travelEdit = gql`
  mutation travelEdit(
    $_id: String!
    $title: String!
    $pictures: [String]!
    $about: String!
    $price: Int!
    $dayCount: Int!
    $packages: [PACKAGES]
    $categoryId: String!
    $contentCategoryIds: [String]!
    $days: [TravelDayInput]!
  ) {
    travelEdit(
      _id: $_id
      pictures: $pictures
      title: $title
      dayCount: $dayCount
      about: $about
      price: $price
      packages: $packages
      categoryId: $categoryId
      contentCategoryIds: $contentCategoryIds
      days: $days
    )
  }
`;

const travelDelete = gql`
  mutation travelDelete($_id: String!) {
    travelDelete(_id: $_id)
  }
`;

const travelLock = gql`
  mutation travelLock($_id: String!, $isActive: Boolean!) {
    travelLock(_id: $_id, isActive: $isActive)
  }
`;

const travelIsSpecial = gql`
  mutation travelIsSpecial($_id: String!) {
    travelIsSpecial(_id: $_id)
  }
`;

const changeStatusUserRequest = gql`
  mutation changeStatusUserRequest(
    $_id: String!
    $status: STATUS!
    $reason: String
  ) {
    changeStatusUserRequest(_id: $_id, status: $status, reason: $reason)
  }
`;

const orderEdit = gql`
  mutation orderEdit($_id: String!, $status: ORDERSTATUS!, $reason: String) {
    orderEdit(_id: $_id, status: $status, reason: $reason)
  }
`;

const bankAccountCreate = gql`
  mutation bankAccountCreate(
    $bankCode: String!
    $bankName: String!
    $bankAccountName: String!
    $bankAccount: Float!
    $password: String!
  ) {
    bankAccountCreate(
      bankCode: $bankCode
      bankName: $bankName
      bankAccountName: $bankAccountName
      bankAccount: $bankAccount
      password: $password
    )
  }
`;

const bankAccountChange = gql`
  mutation bankAccountChange(
    $_id: String!
    $bankCode: String!
    $bankName: String!
    $bankAccountName: String!
    $bankAccount: Float!
    $password: String!
  ) {
    bankAccountChange(
      _id: $_id
      bankCode: $bankCode
      bankName: $bankName
      bankAccountName: $bankAccountName
      bankAccount: $bankAccount
      password: $password
    )
  }
`;
const userConfirmEdit = gql`
  mutation userConfirmEdit(
    $_id: String!
    $status: USERCONFIRMSTATUS!
    $reason: String
  ) {
    userConfirmEdit(_id: $_id, status: $status, reason: $reason)
  }
`;

const articleRemove = gql`
  mutation articleRemove($_id: String!) {
    articleRemove(_id: $_id)
  }
`;

const articleAdd = gql`
  mutation articleAdd(
    $title: String!
    $description: String!
    $pictures: [String]!
    $articleTypeIds: [String]!
    $tourItemIncluded: [String]
    $tourItemNotIncluded: [String]
    $day: Int!
    $tourPrice: Float!
    $type: articletype!
    $countryCategoryId: String!
    $information: String
  ) {
    articleAdd(
      title: $title
      description: $description
      pictures: $pictures
      articleTypeIds: $articleTypeIds
      tourItemIncluded: $tourItemIncluded
      tourItemNotIncluded: $tourItemNotIncluded
      day: $day
      tourPrice: $tourPrice
      type: $type
      countryCategoryId: $countryCategoryId
      information: $information
    )
  }
`;

const articleEdit = gql`
  mutation articleEdit(
    $_id: String!
    $title: String!
    $description: String!
    $pictures: [String]!
    $articleTypeIds: [String]!
    $tourItemIncluded: [String]
    $tourItemNotIncluded: [String]
    $day: Int!
    $tourPrice: Float!
    $type: articletype!
    $countryCategoryId: String!
    $information: String
  ) {
    articleEdit(
      _id: $_id
      title: $title
      description: $description
      pictures: $pictures
      articleTypeIds: $articleTypeIds
      tourItemIncluded: $tourItemIncluded
      tourItemNotIncluded: $tourItemNotIncluded
      day: $day
      tourPrice: $tourPrice
      type: $type
      countryCategoryId: $countryCategoryId
      information: $information
    )
  }
`;

const postDelete = gql`
  mutation postDelete($_id: String!) {
    postDelete(_id: $_id)
  }
`;

const ownPostCommentRemove = gql`
  mutation ownPostCommentRemove($postId: String!, $_id: String!) {
    ownPostCommentRemove(postId: $postId, _id: $_id)
  }
`;

const userIsActive = gql`
  mutation userIsActive($_id: String!) {
    userIsActive(_id: $_id)
  }
`;

const countryCategoryDelete = gql`
  mutation countryCategoryDelete($_id: String!) {
    countryCategoryDelete(_id: $_id)
  }
`;
const contentCategoryRemove = gql`
  mutation contentCategoryRemove($_id: String!) {
    contentCategoryRemove(_id: $_id)
  }
`;

const articleTypeRemove = gql`
  mutation articleTypeRemove($_id: String!) {
    articleTypeRemove(_id: $_id)
  }
`;

const articleTypeCreate = gql`
  mutation articleTypeCreate($name: String!) {
    articleTypeCreate(name: $name)
  }
`;

const articleTypeUpdate = gql`
  mutation articleTypeUpdate($_id: String!, $name: String!) {
    articleTypeUpdate(name: $name, _id: $_id)
  }
`;

const skillCreate = gql`
  mutation skillCreate($name: String!) {
    skillCreate(name: $name)
  }
`;

const skillUpdate = gql`
  mutation skillUpdate($_id: String!, $name: String!) {
    skillUpdate(name: $name, _id: $_id)
  }
`;

const skillRemove = gql`
  mutation skillRemove($_id: String!) {
    skillRemove(_id: $_id)
  }
`;

const travelScheduleAdd = gql`
  mutation travelScheduleAdd($startDate: Date!, $travelId: String!) {
    travelScheduleAdd(startDate: $startDate, travelId: $travelId)
  }
`;

const travelScheduleRemove = gql`
  mutation travelScheduleRemove($_id: String!) {
    travelScheduleRemove(_id: $_id)
  }
`;
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login,
  forgotPassword,
  resetPassword,
  userCreate,
  userUpdate,
  userIsSpecial,
  changePassword,
  blogAdd,
  blogEdit,
  blogRemove,
  blogSpecial,
  contentCategoryAdd,
  contentCategoryEdit,
  countryCategoryAdd,
  countryCategoryEdit,
  pageCreate,
  pageUpdate,
  pageRemove,
  travelAdd,
  travelEdit,
  travelDelete,
  travelLock,
  travelIsSpecial,
  changeStatusUserRequest,
  orderEdit,
  bankAccountCreate,
  bankAccountChange,
  userConfirmEdit,
  articleRemove,
  articleAdd,
  articleEdit,
  postDelete,
  ownPostCommentRemove,
  userIsActive,
  countryCategoryDelete,
  contentCategoryRemove,
  articleTypeRemove,
  articleTypeCreate,
  articleTypeUpdate,
  skillCreate,
  skillUpdate,
  skillRemove,
  travelScheduleAdd,
  travelScheduleRemove,
};
