import * as React from "react";
import { useQuery } from "@apollo/client";
import { queries } from "../../graphql";

const withCurrentUser = (Component) => {
  const Container = (props) => {
    const { data: currentUserQuery, loading } = useQuery(queries.currentUser);

    if (loading) {
      return null;
    }

    const { currentUser } = currentUserQuery || {};

    const updatedProps = {
      ...props,
      currentUser: currentUser,
    };

    return <Component {...updatedProps} />;
  };

  return Container;
};

export default withCurrentUser;
