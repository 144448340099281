import {
  ApolloClient,
  createHttpLink,
  split,
  InMemoryCache,
  ApolloLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";

const { REACT_APP_API_URL } = process.env;

// Create an http link:
const httpLink = createHttpLink({
  uri: `${REACT_APP_API_URL}/graphql`,
});

const middlewareLink = setContext(() => ({
  headers: {
    "x-token": localStorage.getItem("loginToken"),
    "x-refresh-token": localStorage.getItem("loginRefreshToken"),
  },
}));

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const token = headers.get("x-token");
      const refreshToken = headers.get("x-refresh-token");

      if (token) {
        localStorage.setItem("loginToken", token);
      }

      if (refreshToken) {
        localStorage.setItem("loginRefreshToken", refreshToken);
      }
    }

    return response;
  });
});

// Network error
const errorLink = onError(({ networkError }) => {
  if (networkError) {
    // message.error("Disconnect ...");
  }
});

// Combining httpLink and warelinks altogether
const httpLinkWithMiddleware = errorLink.concat(
  afterwareLink.concat(middlewareLink.concat(httpLink))
);

// Setting up subscription with link
const link = split(
  // split based on operation type
  ({ query }) => {
    const { kind } = getMainDefinition(query);

    return kind === "OperationDefinition";
  },
  httpLinkWithMiddleware
);

// Creating Apollo-client
const client = new ApolloClient({
  link,
  cache: new InMemoryCache({ addTypename: false }),
  queryDeduplication: false,
  fetchOptions: { mode: "cors" },
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
    },
  },
});

export default client;
