import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
const List = lazy(() => import("./containers/List"));
const Form = lazy(() => import("./containers/Form"));

const ListComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <List history={history} queryParams={queryParams} />
    </Suspense>
  );
};
const FormComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <Form history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const routes = (currentUser, roles) => {
  return currentUser.role === roles.ADMIN
    ? [
        <Route path="/page/list" key="/page/list" component={ListComponent} />,
        <Route path="/page/form" key="/page/form" component={FormComponent} />,
      ]
    : [];
};

export default routes;
