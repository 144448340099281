import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
const Form = lazy(() => import("./containers/Form"));

const FormComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <Form history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const routes = (currentUser, roles) => {
  return currentUser.role === roles.ADMIN || currentUser.role === roles.COMPANY
    ? [
        <Route
          path="/schedule/form"
          key="/schedule/form"
          component={FormComponent}
        />,
      ]
    : [];
};

export default routes;
