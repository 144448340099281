import { Suspense } from "react";
import { render } from "react-dom";
import { ApolloProvider } from "@apollo/client";
import "./assets/icons/remixicon.css";
import "./assets/less/app_solucation_template.less";
import reportWebVitals from "./reportWebVitals";
import apolloClient from "./lib/apolloClient";
import Routes from "./routes";

const target = document.querySelector("#root");
render(
  <Suspense fallback="loading">
    <ApolloProvider client={apolloClient}>
      <Routes />
    </ApolloProvider>
  </Suspense>,
  target
);

reportWebVitals();
