import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
const List = lazy(() => import("./containers/List"));
const Detail = lazy(() => import("./containers/Detail"));

const ListComponent = ({ location, history, match }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <List history={history} queryParams={queryParams} id={match.params.id} />
    </Suspense>
  );
};

const FormComponent = ({ location, history, match }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <Detail
        history={history}
        queryParams={queryParams}
        id={match.params.id}
      />
    </Suspense>
  );
};

const routes = (currentUser, roles) => {
  return currentUser.role === roles.ADMIN
    ? [
        <Route
          path="/order/list"
          key="/order/list"
          component={ListComponent}
        />,
        <Route
          path="/order/detail"
          key="/order/detail"
          component={FormComponent}
        />,
      ]
    : [];
};

export default routes;
