import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
const List = lazy(() => import("./containers/List"));
const MyList = lazy(() => import("./containers/MyList"));
const Detail = lazy(() => import("./containers/Detail"));

const ListComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <List history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const MyListComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <MyList history={history} queryParams={queryParams} />
    </Suspense>
  );
};
const DetailComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <Detail history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const routes = (currentUser, ROLES) => {
  return [
    <Route
      path="/post/list"
      key="/post/list"
      exact={true}
      component={
        currentUser.role === ROLES.ADMIN ? ListComponent : MyListComponent
      }
    />,
    <Route
      path="/post/detail"
      key="/post/detail"
      component={DetailComponent}
    />,
  ];
};

export default routes;
