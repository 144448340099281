import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import queryString from "query-string";
import { Spin } from "antd";
const UserInfo = lazy(() => import("./containers/Main"));
const LogOut = lazy(() => import("./containers/LogOut"));

const UserInfoComponent = ({ location, history, match }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <UserInfo
        history={history}
        queryParams={queryParams}
        id={match.params.id}
      />
    </Suspense>
  );
};

const LogOutComponent = ({ location, history }) => {
  const queryParams = queryString.parse(location.search);

  return (
    <Suspense fallback={<Spin />}>
      <LogOut history={history} queryParams={queryParams} />
    </Suspense>
  );
};

const routes = () => [
  <Route path="/me/:id" key="/me/:id" component={UserInfoComponent} />,
  <Route path="/auth/logout" key="/auth/logout" component={LogOutComponent} />,
];

export default routes;
